@use "sass:math";
@use "../global-variables" as global;
@use "codyframe/main/scss/reset";
@use "codyframe/main/scss/config" as * with (
  $breakpoints: (
    "xs": "32rem",
    "sm": "48rem",
    "md": "64rem",
    "lg": "80rem",
    "xl": "90rem",
  ),
  $spacing: (
    "@all": (
      "xxxxs": "0.25rem",
      "xxxs": "0.5rem",
      "xxs": "0.625rem",
      "xs": "0.75rem",
      "sm": "0.875rem",
      "md": "1rem",
      "lg": "1.5rem",
      "xl": "2rem",
      "xxl": "2.5rem",
      "xxxl": "3.5rem",
      "xxxxl": "5rem",
    ),
  ),
  $font-family: (
    "primary": "Roboto, system-ui, sans-serif;",
    "secondary": "Roboto, system-ui, sans-serif;",
  ),
  $font-size: (
    "@all": (
      "xxs": #{math.div(global.$font-size-xxs, global.$browser-default-font-size) * 1rem},
      "xs": #{math.div(global.$font-size-xs, global.$browser-default-font-size) * 1rem},
      "sm": #{math.div(global.$font-size-sm, global.$browser-default-font-size) * 1rem},
      "base": #{math.div(global.$font-size-base, global.$browser-default-font-size) * 1rem},
      "md": #{math.div(global.$font-size-md, global.$browser-default-font-size) * 1rem},
      "lg": #{math.div(global.$font-size-lg, global.$browser-default-font-size) * 1rem},
      "xl": #{math.div(global.$font-size-xl, global.$browser-default-font-size) * 1rem},
      "xxl": #{math.div(global.$font-size-xxl, global.$browser-default-font-size) * 1rem},
      "xxxl": #{math.div(global.$font-size-xxxl, global.$browser-default-font-size) * 1rem},
      "xxxxl": #{math.div(global.$font-size-xxxxl, global.$browser-default-font-size) * 1rem},
    ),
  ),
  $aspect-ratio: (
    16 9,
    3 2,
    4 3,
    5 4,
    1 1,
  ),
  $media-wrapper: (
    16 9,
    3 2,
    4 3,
    1 1,
  ),
  $border-radius: (
    "sm": "0.1875em",
    "md": "0.375em",
    "lg": "0.75em",
  ),
  $colors: (
    "default": (
      "primary": (
        "darker": "288, 59%, 28%",
        "dark": "288, 59%, 34%",
        "base": "288, 59%, 38%",
        "light": "288, 59%, 43%",
        "lighter": "288, 59%, 46%",
      ),
      "accent": (
        "darker": "333, 100%, 24%",
        "dark": "333, 100%, 34%",
        "base": "333, 100%, 40%",
        "light": "333, 100%, 54%",
        "lighter": "333, 100%, 64%",
      ),
      "black": (
        "base": "204, 28%, 7%",
      ),
      "white": (
        "base": "0, 0%, 100%",
      ),
      "warning": (
        "darker": "43, 99%, 29%",
        "dark": "43, 99%, 39%",
        "base": "43, 99%, 49%",
        "light": "47, 100%, 83%",
        "lighter": "47, 100%, 90%",
      ),
      "success": (
        "darker": "164, 86%, 16%",
        "dark": "158, 95%, 22%",
        "base": "158, 95%, 32%",
        "light": "158, 54%, 54%",
        "lighter": "159, 65%, 86%",
      ),
      "error": (
        "darker": "0, 63%, 31%",
        "dark": "0, 74%, 42%",
        "base": "11, 83%, 50%",
        "light": "0, 94%, 82%",
        "lighter": "14, 100%, 92%",
      ),
      "bg": (
        "darker": "0, 0%, 85%",
        "dark": "0, 0%, 93%",
        "base": "0, 0%, 100%",
        "light": "180, 3%, 100%",
        "lighter": "210, 4%, 100%",
      ),
      "contrast": (
        "lower": "0, 0%, 92%",
        "low": "240, 1%, 83%",
        "medium": "240, 1%, 48%",
        "high": "240, 4%, 20%",
        "higher": "240, 8%, 12%",
      ),
    ),
    "dark": (
      "primary": (
        "darker": "34, 99%, 45%",
        "dark": "34, 99%, 51%",
        "base": "34, 99%, 57%",
        "light": "34, 99%, 63%",
        "lighter": "34, 99%, 69%",
      ),
      "accent": (
        "darker": "333, 100%, 38%",
        "dark": "333, 100%, 44%",
        "base": "333, 100%, 50%",
        "light": "333, 100%, 56%",
        "lighter": "333, 100%, 62%",
      ),
      "black": (
        "base": "204, 28%, 7%",
      ),
      "white": (
        "base": "0, 0%, 100%",
      ),
      "warning": (
        "darker": "46, 100%, 47%",
        "dark": "46, 100%, 50%",
        "base": "46, 100%, 61%",
        "light": "46, 100%, 71%",
        "lighter": "46, 100%, 80%",
        "bg": "25, 18%, 19%",
      ),
      "success": (
        "darker": "151, 76%, 51%",
        "dark": "151, 76%, 51%",
        "base": "151, 76%, 51%",
        "light": "151, 76%, 51%",
        "lighter": "151, 76%, 51%",
        "bg": "158, 26%, 16%",
      ),
      "error": (
        "darker": "349, 93%, 40%",
        "dark": "349, 93%, 46%",
        "base": "349, 93%, 52%",
        "light": "349, 93%, 58%",
        "lighter": "349, 93%, 64%",
        "bg": "351, 40%, 17%",
      ),
      "bg": (
        "darker": "204, 68%, 3%",
        "dark": "240, 65%, 6%",
        "base": "240, 62%, 8%",
        "light": "240, 36%, 15%",
        "lighter": "240, 15%, 20%",
      ),
      "contrast": (
        "lower": "264, 10%, 20%",
        "low": "264, 8%, 25%",
        "medium": "264, 4%, 47%",
        "high": "262, 7%, 77%",
        "higher": "262, 11%, 85%",
      ),
    ),
  ),
  $width: (
    "xxxxs": "0.25rem",
    "xxxs": "0.5rem",
    "xxs": "1rem",
    "xs": "2rem",
    "sm": "3rem",
    "md": "5rem",
    "lg": "8rem",
    "xl": "13rem",
    "xxl": "16rem",
    "xxxl": "21rem",
    "xxxxl": "34rem",
    0: "0",
    10\%: "10%",
    20\%: "20%",
    25\%: "25%",
    30\%: "30%",
    33\%: "33%",
    40\%: "40%",
    50\%: "50%",
    60\%: "60%",
    70\%: "70%",
    75\%: "75%",
    80\%: "80%",
    90\%: "90%",
    100\%: "100%",
  ),
  $height: (
    "xxxxs": "0.25rem",
    "xxxs": "0.5rem",
    "xxs": "1rem",
    "xs": "2rem",
    "sm": "3rem",
    "md": "5rem",
    "lg": "8rem",
    "xl": "13rem",
    "xxl": "16rem",
    "xxxl": "21rem",
    "xxxxl": "34rem",
    0: "0",
    10\%: "10%",
    20\%: "20%",
    25\%: "25%",
    30\%: "30%",
    33\%: "33%",
    40\%: "40%",
    50\%: "50%",
    60\%: "60%",
    70\%: "70%",
    75\%: "75%",
    80\%: "80%",
    90\%: "90%",
    100\%: "100%",
  )
);
@use "codyframe/main/scss/util";
@use "codyframe/main/scss/icons";
@use "codyframe/main/scss/buttons";
@use "codyframe/main/scss/forms";
@use "typography";
@use "size";
@use "colors-charts";

.text-pre-line {
  white-space: pre-line;
}
