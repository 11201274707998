tui-tabs {
  box-shadow: none !important;

  /* TODO: lets align on the fonts already and have all of them at one place */
  --tui-font-text-m: 500 14px/16px Roboto;

  tui-underline {
    --tui-primary: var(--tui-accent);
  }
}
