@import "./wrapper/base";
@import "./wrapper/radio";
@import "./wrapper/icon";
@import "./wrapper/outline";
@import "./wrapper/primary";
@import "./wrapper/secondary";
@import "./wrapper/accent";
@import "./wrapper/textfield";
@import "./wrapper/whiteblock";
@import "./wrapper/flat-in-menu";
@import "./wrapper/success";
@import "./wrapper/error";
@import "./wrapper/deactivated";

// For the side menu we want a different background color
// So we overwrite here the default variable
// Todo: Maybe find a better place or use ng-deep / ViewEncapsulation.None in app-header
[tuisidebar] > .t-wrapper {
  --tui-base-01: var(--tui-base-09);
}
