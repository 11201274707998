@use "@taiga-ui/core/styles/taiga-ui-local" as tui;

tui-wrapper[data-appearance="whiteblock"],
tui-wrapper[data-appearance="whiteblock-active"],
[tuiWrapper][data-appearance="whiteblock"],
[tuiWrapper][data-appearance="whiteblock-active"] {
  background: var(--tui-base-01);
  color: var(--tui-text-01);

  &:after {
    color: var(--tui-base-03);
  }

  @include tui.wrapper-hover {
    background: var(--tui-base-02);

    &:after {
      color: var(--tui-base-04);
    }
  }

  @include tui.wrapper-active {
    background: var(--tui-base-02);

    &:after {
      color: var(--tui-base-04);
    }
  }
}

tui-wrapper[data-appearance="whiteblock-active"],
[tuiWrapper][data-appearance="whiteblock-active"] {
  &:after {
    color: var(--tui-primary);
    border-width: 2px;
  }

  @include tui.wrapper-hover {
    &:after {
      color: var(--tui-primary-hover);
    }
  }

  @include tui.wrapper-active {
    &:after {
      color: var(--tui-primary-active);
    }
  }
}
