:root {
  // Deprecated
  --tui-heading-font: var(--font-primary);
  --tui-text-font: var(--font-primary);

  // Fonts
  --tui-font-heading: var(--tui-heading-font);
  --tui-font-heading-1: bold var(--text-xxxl) / 3.5rem var(--font-primary);
  --tui-font-heading-2: bold var(--text-xxl) / 3rem var(--font-primary);
  --tui-font-heading-3: bold var(--text-xl) / 2.5rem var(--font-primary);
  --tui-font-heading-4: bold var(--text-lg) / 2rem var(--font-primary);
  --tui-font-heading-5: bold var(--text-md) / 1.75rem var(--font-primary);
  --tui-font-heading-6: bold var(--text-base) / 1.5rem var(--font-primary);
  --tui-font-text: var(--tui-text-font);
  --tui-font-text-xl: normal var(--text-lg) / 1.75rem var(--font-primary);
  --tui-font-text-l: normal var(--text-md) / 1.5rem var(--font-primary);
  --tui-font-text-m: normal var(--text-base) / 1.5rem var(--font-primary);
  --tui-font-text-s: normal var(--text-sm) / 1.25rem var(--font-primary);
  --tui-font-text-xs: normal var(--text-xs) / 1rem var(--font-primary);

  // Sizes
  --tui-radius-xs: 0.1rem;
  --tui-radius-s: 0.16rem;
  --tui-radius-m: 0.25rem;
  --tui-radius-l: 0.5rem;
  --tui-height-xs: 1.5rem;
  --tui-height-s: 2rem;
  --tui-height-m: 2.75rem;
  --tui-height-l: 3.5rem;

  // Input padding
  --tui-padding-s: 0.75rem;
  --tui-padding-m: 0.75rem;
  --tui-padding-l: 1rem;

  // Misc
  --tui-duration: 0.3s;

  // Special colors
  --tui-disabled-opacity: 0.56;
  --tui-autofill: #fff5c0;
  --tui-elevation-01: var(--color-bg);
  --tui-elevation-02: var(--color-contrast-lower);
  --tui-column-bg: #ffffff; // changing to darker in dark mode

  // Todo: Use Codyhouse colors for base of Taiga (Use BG and Contrast scale, add more if needed)
  // Base colors
  --tui-base-white: #fff; // white
  --tui-base-01: #f8fafc; // background and icons on inverted background
  --tui-base-02: #f2f2f7; // secondary background, marker icons background
  --tui-base-03: #e8e8ed; // basic border
  --tui-base-04: #d7d7e0; // hovered borders
  --tui-base-05: #a1a1cc; // interface icons
  --tui-base-06: #7b7bb2; // hovered interface icons
  --tui-base-07: #292966; // inverted background
  --tui-base-08: #0f0f4d; // dark interface icons
  --tui-base-09: #000032; // icons on inverted background

  --tui-primary: var(--color-primary); // primary buttons, background
  --tui-primary-hover: var(--color-primary-lighter); // primary buttons hover
  --tui-primary-active: var(--color-primary-dark); // primary buttons hover
  --tui-primary-text: var(--color-white); // text on primary background

  // Todo: Define secondary color in Codyhouse?
  --tui-secondary: #ebefff; // inputs and secondary buttons
  --tui-secondary-hover: #dfe3f3; // inputs and secondary buttons hover
  --tui-secondary-active: #d8ddf2; // inputs and secondary buttons pressed

  --tui-accent: var(--color-accent); // fill and outline of accent elements
  --tui-accent-hover: var(--color-accent-lighter); // accent elements hover
  --tui-accent-active: var(--color-accent-darker); // accent elements pressed
  --tui-accent-text: var(--color-white); // text on accent background

  --tui-selection: rgba(112, 182, 246, 0.12); // selected text background
  --tui-focus: rgba(51, 51, 51, 0.64); // focus ring color
  --tui-clear: rgba(0, 0, 0, 0.08); // translucent dark fill
  --tui-clear-disabled: rgba(0, 0, 0, 0.04); // translucent dark fill disabled
  --tui-clear-hover: rgba(0, 0, 0, 0.16); // translucent dark fill hover
  --tui-clear-active: rgba(0, 0, 0, 0.2); // translucent dark fill pressed
  --tui-clear-inverse: rgba(255, 255, 255, 0.16); // translucent light fill
  --tui-clear-inverse-hover: rgba(255, 255, 255, 0.24); // translucent light fill hover
  --tui-clear-inverse-active: rgba(255, 255, 255, 0.4); // translucent light fill pressed

  // Todo: Use Codyhouse colors for status things of Taiga
  // Statuses
  --tui-error-fill: #e83c16; // icons and decorative elements with error status
  --tui-error-bg: #ffe0d7; // translucent error background
  --tui-error-bg-hover: rgba(244, 87, 37, 0.24); // translucent hover error background

  --tui-success-fill: #049d65; // icon and decorative elements with success status
  --tui-success-bg: #c6f3e3; // translucent success background
  --tui-success-bg-hover: rgba(74, 201, 155, 0.24); // translucent success hover

  --tui-warning-fill: #fab401; // icon and decorative elements with warning status
  --tui-warning-bg: #fff4cc; // translucent warning background
  --tui-warning-bg-hover: rgba(255, 199, 0, 0.24); // translucent warning background

  --tui-info-fill: #0796c3; // icon and decorative elements with info status
  --tui-info-bg: #c0f0ff; // translucent info background
  --tui-info-bg-hover: rgba(112, 182, 246, 0.24); // translucent info background

  --tui-neutral-fill: #70748c; // icon and decorative elements with neutral status
  --tui-neutral-bg: #dce1e9; // translucent info background
  --tui-neutral-bg-hover: rgba(121, 129, 140, 0.24); // translucent info background

  // Text
  --tui-text-01: rgba(27, 31, 59, 0.8);
  --tui-text-02: rgba(27, 31, 59, 0.65);
  --tui-text-03: #70748c; //rgba(27, 31, 59, 0.4);

  // Todo: This was added by Tomas for the menu
  // Todo: discuss -> do we not have enough colors?
  --tui-text-04: #d7d9e4;

  --tui-link: var(--color-primary);
  --tui-link-hover: var(--color-primary-darker);
  --tui-positive: #3aa981;
  --tui-positive-hover: #7ac5aa;
  --tui-negative: #dd4c1e;
  --tui-negative-hover: #e38163;

  // Modifiers for dark background
  --tui-error-fill-night: rgba(255, 140, 103, 1);
  --tui-error-bg-night: rgba(244, 87, 37, 0.32);
  --tui-error-bg-night-hover: rgba(244, 87, 37, 0.4);
  --tui-success-fill-night: rgb(74, 201, 155);
  --tui-success-bg-night: rgba(74, 201, 155, 0.32);
  --tui-success-bg-night-hover: rgba(74, 201, 155, 0.4);
  --tui-warning-fill-night: rgb(255, 199, 0);
  --tui-warning-bg-night: rgba(255, 199, 0, 0.32);
  --tui-warning-bg-night-hover: rgba(255, 199, 0, 0.4);
  --tui-info-fill-night: rgb(112, 182, 246);
  --tui-info-bg-night: rgba(112, 182, 246, 0.32);
  --tui-info-bg-night-hover: rgba(112, 182, 246, 0.4);
  --tui-neutral-fill-night: rgb(149, 155, 164);
  --tui-neutral-bg-night: rgb(149, 155, 164, 0.32);
  --tui-neutral-bg-night-hover: rgb(149, 155, 164, 0.48);
  //
  --tui-text-01-night: rgba(255, 255, 255, 1);
  --tui-text-02-night: rgba(255, 255, 255, 0.72);
  --tui-text-03-night: rgba(255, 255, 255, 0.6);
  --tui-link-night: #6788ff;
  --tui-link-night-hover: #526ed3;
  --tui-positive-night: #44c596;
  --tui-positive-night-hover: #3aa981;
  --tui-negative-night: #ff8c67;
  --tui-negative-night-hover: #bb593a;
  //
  --tui-support-01: #a8cef1;
  --tui-support-02: #3682db;
  --tui-support-03: #8dda71;
  --tui-support-04: #34b41f;
  --tui-support-05: #e29398;
  --tui-support-06: #b8474e;
  --tui-support-07: #fcc068;
  --tui-support-08: #ff8a00;
  --tui-support-09: #dab3f9;
  --tui-support-10: #7b439e;
  --tui-support-11: #fee797;
  --tui-support-12: #fcbb14;
  --tui-support-13: #ea97c4;
  --tui-support-14: #bd65a4;
  --tui-support-15: #7fd7cc;
  --tui-support-16: #2fad96;
  --tui-support-17: #d4aca2;
  --tui-support-18: #9d6f64;
  --tui-support-19: #d2e9a2;
  --tui-support-20: #aadc42;
  --tui-support-21: #a0c5df;
  --tui-support-22: #3c7ba8;
  --tui-support-23: #404082;
}
