@use "@taiga-ui/core/styles/taiga-ui-local" as tui;

tui-wrapper[data-appearance="outline"],
[tuiWrapper][data-appearance="outline"],
tui-wrapper[data-appearance="ion-outline"],
[tuiWrapper][data-appearance="ion-outline"] {
  background: transparent;
  color: var(--tui-text-01);

  &:after {
    color: var(--tui-base-04);
  }

  @include tui.wrapper-hover {
    &:after {
      color: var(--tui-base-05);
    }
  }

  @include tui.wrapper-active {
    &:after {
      color: var(--tui-base-06);
    }
  }

  &[data-mode="onDark"] {
    color: var(--tui-text-01-night);

    &:after {
      color: var(--tui-text-01-night);
    }

    @include tui.wrapper-hover {
      background: var(--tui-clear-inverse-hover);

      &:after {
        color: var(--tui-text-03-night);
      }
    }

    @include tui.wrapper-active {
      background: var(--tui-clear-inverse-active);

      &:after {
        color: var(--tui-text-03-night);
      }
    }

    @include tui.wrapper-focus {
      --tui-focus: var(--tui-text-01-night);
    }
  }

  &[data-mode="onLight"] {
    color: var(--tui-text-01);

    &:after {
      color: var(--tui-text-01);
    }

    @include tui.wrapper-hover {
      background: var(--tui-clear-hover);

      &:after {
        color: var(--tui-text-03);
      }
    }

    @include tui.wrapper-active {
      background: var(--tui-clear-active);

      &:after {
        color: var(--tui-text-03);
      }
    }
  }
}

tui-wrapper[data-appearance="ion-outline"],
[tuiWrapper][data-appearance="ion-outline"] {
  background: transparent;
  color: var(--tui-primary);

  &:after {
    color: var(--tui-base-04);
    border: 1px solid var(--tui-base-05);
  }

  @include tui.wrapper-hover {
    &:after {
      color: var(--tui-base-05);
      border-color: var(--tui-primary);
    }
  }
}

tui-wrapper[data-appearance="ion-outline-destructive"],
[tuiWrapper][data-appearance="ion-outline-destructive"] {
  background: transparent;
  color: var(--color-error);

  &:after {
    @include tui.transition(color);
    color: var(--tui-error-bg-hover); // border-color is currentColor
  }

  @include tui.wrapper-hover {
    &:after {
      color: var(--color-error);
    }
  }

  @include tui.wrapper-active {
    &:after {
      color: var(--color-error);
    }
  }
}
