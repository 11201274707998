@import "./variables";
@import "./variables-dark";
@import "./wrapper";
@import "./hint";
@import "./tabs";
@import "./notification";
@import "./icons";
@import "./dialog";
@import "./slider";
@import "./tag";
@import "./dropdown";
@import "./marker-icon";
@import "./segmented";

// Add support for tuiTextfieldSize 's' in tui-text-area.
tui-textarea[data-size="s"] {
  // Fix font-size and line-height
  font: var(--tui-font-text-s);

  &._label-outside .t-wrapper {
    // Fixes vertical alignment of first line of text-area compared to label
    padding-top: 0.375rem;
  }

  .t-input,
  .t-pseudo-content {
    // Fix left padding. Otherwise text would be hidden behind the element's border.
    padding: 0 var(--tui-padding-s);
  }
}
