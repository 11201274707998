// Overwrite Taiga UI settings to have responsive icons
tui-svg:not(.t-mark) {
  height: 1em !important;
  width: 1em !important;
  font-size: 1.5rem !important; // 24px by default
  fill: currentColor;
}

// Buttons
// ***************

// We use Material icons and apply a way to use font sizes of any size for scaling
// Taiga UI uses pixels for their icon (although in rem) and apply only two sizes: 16px for XS,S and 24px for M,L,XL
// We need to overwrite those hardcoded settings in `button.style.less` here because XL is using 32px and S is using 20px
[tuiButton],
[tuiIconButton] {
  &[data-size="xl"],
  &[data-size="l"],
  &[data-size="m"],
  &[data-size="s"],
  &[data-size="xs"] {
    .t-left,
    .t-right {
      width: auto !important;
      height: auto !important;
    }
  }
}

[tuiButton]:not(.t-close),
[tuiIconButton]:not(.t-close) {
  &[data-size="xl"] {
    tui-svg {
      font-size: 2rem !important; // 32px
    }
  }
}

[tuiOption],
[tuiButton]:not(.t-close),
[tuiIconButton]:not(.t-close) {
  &[data-size="l"] {
    tui-svg {
      font-size: 1.5rem !important; // 24px (same as default)
    }
  }
}

[tuiOption],
[tuiButton]:not(.t-close),
[tuiIconButton]:not(.t-close) {
  &[data-size="m"] {
    tui-svg {
      font-size: 1.5rem !important; // 24px (same as default)
    }
  }
}

[tuiOption],
[tuiButton]:not(.t-close),
[tuiIconButton]:not(.t-close) {
  &[data-size="s"] tui-svg {
    font-size: 1.25rem !important; // 16px
  }
}

[tuiOption],
[tuiButton]:not(.t-close),
[tuiIconButton]:not(.t-close) {
  &[data-size="xs"] {
    tui-svg {
      font-size: 1rem !important; // 16px
    }
  }
}

// DataList options
// ***************

// Taiga by default doesn't have support for icons on the left of tuiOption labels.
// Values taken from https://github.com/taiga-family/taiga-ui/blob/v3.92.0/projects/core/components/button/button.style.less
// Sizing selectors taken from https://github.com/taiga-family/taiga-ui/blob/v3.92.0/projects/core/components/data-list/option/option.style.less
tui-svg.icon {
  [data-list-size="xs"] &,
  [tuiOption][data-size="xs"] & {
    margin-right: 0.25rem;
  }
  [data-list-size="s"] &,
  [tuiOption][data-size="s"] & {
    margin-right: 0.375rem;
  }

  [data-list-size="m"] &,
  [data-list-size="l"] &,
  [tuiOption][data-size="m"] &,
  [tuiOption][data-size="l"] & {
    margin-right: 0.5rem;
  }
}

// Checkbox
// ***************

tui-checkbox[data-size="m"] tui-svg {
  // 'm' is the small one, so use small icon
  font-size: 1rem !important;
}
