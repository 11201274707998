tui-hint {
  --tui-primary: var(--tui-base-09) !important;
  --tui-primary-text: var(--color-white) !important;
}

// Todo: This is a trick at the moment, see menu-button -> [tuiHintAppearance]="$any('forMenu')"
// Todo: Adapt to new tuiHintAppearance
tui-hint[data-appearance="forMenu"] {
  padding: 0 !important;
}
