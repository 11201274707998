:root {
  // Default
  --color-chart-default-000: hsla(21, 92%, 95%, 1);
  --color-chart-default-100: hsla(355, 78%, 84%, 1);
  --color-chart-default-200: hsla(340, 80%, 70%, 1);
  --color-chart-default-300: hsla(333, 100%, 40%, 1);
  --color-chart-default-400: hsla(333, 100%, 27%, 1);

  // Secondary
  --color-chart-secondary-000: hsla(257, 60%, 93%, 1);
  --color-chart-secondary-100: hsla(270, 59%, 78%, 1);
  --color-chart-secondary-200: hsla(260, 55%, 60%, 1);
  --color-chart-secondary-300: hsla(288, 59%, 38%, 1);
  --color-chart-secondary-400: hsla(263, 60%, 32%, 1);

  // Positive
  --color-chart-positive-000: hsla(88, 70%, 93%, 1);
  --color-chart-positive-100: hsla(25, 66%, 82%, 1);
  --color-chart-positive-200: hsla(148, 80%, 62%, 1);
  --color-chart-positive-300: hsla(168, 95%, 39%, 1);
  --color-chart-positive-400: hsla(158, 95%, 22%, 1);

  // Neutral
  --color-chart-neutral-000: hsla(235, 94%, 93%, 1);
  --color-chart-neutral-100: hsla(224, 93%, 79%, 1);
  --color-chart-neutral-200: hsla(184, 70%, 50%, 1);
  --color-chart-neutral-300: hsla(194, 93%, 40%, 1);
  --color-chart-neutral-400: hsla(194, 94%, 20%, 1);
}
