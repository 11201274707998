@use "codyframe/main/scss/config" as *;

// Firefox
// ****************

* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-contrast-lower) var(--color-contrast-low);
}

// Webkit (Chrome, Safari, Opera)
// ****************

// the scrollbar
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

// the buttons on the scrollbar (arrows pointing upwards and downwards)
::-webkit-scrollbar-button {
}

// the track (progress bar) of the scrollbar
::-webkit-scrollbar-track {
  background: var(--color-contrast-low);
}

// the track (progress bar) NOT covered by the handle
::-webkit-scrollbar-track-piece {
}

// the draggable scrolling handle
::-webkit-scrollbar-thumb {
  background-color: alpha(var(--color-contrast-medium), 0.66);
}

// the bottom corner of the scrollbar, where both horizontal and vertical scrollbars meet
::-webkit-scrollbar-corner {
  background: var(--color-contrast-lower);
}

// the draggable resizing handle that appears at the bottom corner of some elements
::-webkit-resizer {
}
