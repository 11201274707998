@use "@taiga-ui/core/styles/taiga-ui-local" as tui;

tui-wrapper,
[tuiWrapper] {
  @include tui.transition(#{color, background});
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  appearance: none;
  border-radius: inherit;

  &:after {
    @include tui.transition(box-shadow);
    @include tui.fullsize(absolute, inset);
    content: "";
    border-radius: inherit;
    border: 1px solid currentColor;
    pointer-events: none;
    color: transparent;
  }

  @include tui.wrapper-focus {
    &:after {
      border-width: 2px;
      color: var(--tui-focus);
    }
  }

  @include tui.wrapper-disabled {
    pointer-events: none;
    opacity: var(--tui-disabled-opacity);
  }
}
