tui-notification {
  border: 1px solid;

  label {
    font: var(--tui-font-text-m);
    font-weight: bold;
  }

  a:link {
    text-decoration: none;
    border-bottom: 1px dotted;
  }
}
