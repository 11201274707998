// Structure
// ***********

//<div class="card">
//  <div class="card__header [card__header--only]">
//    <div class="card__title">
//    <div class="card__actions">
//  </div>
//  <div class="card__body"></div>
//</div>

// Variables
// ***********

:root,
[data-theme="dark"],
[data-mode="onDark"],
[tuimode="onDark"] {
  --card-bg: var(--color-bg-light);
  --card-border-color: var(--tui-base-03);
  --card-body-padding: var(--component-padding);
  --card-header-padding-x: var(--space-sm);
  --card-header-padding-y: var(--space-xs);
}

.no-padding {
  --card-header-padding-x: 0;
  --card-header-padding-y: 0;
}

.no-padding-x {
  --card-header-padding-x: 0;
}

.no-padding-y {
  --card-header-padding-y: 0;
}

.no-body-padding {
  --card-body-padding: 0;
}

// Basics
// ***********

.card {
  background: var(--card-bg);
  border: 1px solid var(--card-border-color);
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
  display: flex;
  flex-direction: column;
  word-break: break-word;
}

.card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--card-border-color);
  padding: var(--card-header-padding-y) var(--card-header-padding-x);

  &.sticky {
    position: sticky;
    top: 0;
    z-index: 999;
    background: var(--card-bg);
  }
}

.card__footer {
  border-top: 1px solid var(--card-border-color);
  padding: var(--card-header-padding-y) var(--card-header-padding-x);
}

.card__header--only {
  border-bottom: none;
}

.card__title {
  display: flex;
  flex-grow: 1;
  align-items: center;
  font-size: var(--text-base);
  font-weight: 700;
}

.card__actions {
  display: flex;
  > * + * {
    margin-left: var(--component-padding);
  }
}

.card__body {
  position: relative;
  padding: var(--card-body-padding);
  flex-grow: 1;
}
