@use "@taiga-ui/core/styles/taiga-ui-local" as tui;

tui-wrapper[data-appearance="radio-checked"],
[tuiWrapper][data-appearance="radio-checked"],
tui-wrapper[data-appearance="radio-unchecked"],
[tuiWrapper][data-appearance="radio-unchecked"] {
  background: var(--color-accent);
  color: var(--color-white);

  @include tui.wrapper-hover {
    background: var(--color-accent-dark);
  }

  @include tui.wrapper-active {
    background: var(--color-accent-dark);
  }

  @include tui.wrapper-invalid {
    color: var(--tui-negative-night);
    background: var(--tui-error-bg);

    @include tui.wrapper-hover {
      background: var(--tui-error-bg-hover);
    }

    @include tui.wrapper-active {
      background: var(--tui-error-bg-hover);
    }

    @include tui.wrapper-focus {
      --tui-focus: var(--tui-error-fill);
    }
  }
}

tui-wrapper[data-appearance="radio-unchecked"],
[tuiWrapper][data-appearance="radio-unchecked"] {
  background: var(--tui-secondary);
  color: var(--tui-secondary);
}
