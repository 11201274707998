@use "@taiga-ui/core/styles/taiga-ui-local" as tui;

tui-wrapper[data-appearance="textfield"],
[tuiWrapper][data-appearance="textfield"] {
  @include tui.transition(#{box-shadow, background});
  background: var(--tui-base-01);
  color: var(--tui-text-01);
  box-shadow: none;

  &[data-appearance="textfield"]:after {
    @include tui.transition(color);
    color: var(--tui-base-03);
  }

  @include tui.wrapper-hover {
    box-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.16);
  }

  @include tui.wrapper-focus {
    box-shadow: none;

    --tui-focus: var(--tui-primary);
  }

  @include tui.wrapper-readonly {
    box-shadow: none;
    background: var(--color-bg-light);
    &:after {
      color: var(--tui-base-02);
    }
  }

  @include tui.wrapper-disabled {
    box-shadow: none;
  }

  @include tui.wrapper-invalid {
    --tui-focus: var(--tui-error-fill);
    &:after {
      color: var(--tui-error-fill);
    }
  }
}

tui-wrapper[data-appearance="textfield"][data-mode="onDark"],
[tuiWrapper][data-appearance="textfield"][data-mode="onDark"] {
  background: var(--tui-clear-inverse);
  color: var(--tui-text-01-night);

  &:after {
    color: var(--tui-text-03-night);
    opacity: var(--tui-disabled-opacity);
  }

  @include tui.wrapper-hover {
    background: var(--tui-clear-inverse-hover);
  }

  @include tui.wrapper-readonly {
    background: transparent;
  }

  @include tui.wrapper-focus {
    background: rgba(255, 255, 255, 0.08);

    --tui-focus: var(--tui-primary);

    &:after {
      transition: none;
      opacity: 1;
    }
  }

  @include tui.wrapper-invalid {
    --tui-focus: var(--tui-error-fill-night);
  }
}

tui-wrapper[data-appearance="textfield"][data-mode="onLight"],
[tuiWrapper][data-appearance="textfield"][data-mode="onLight"] {
  background: var(--tui-clear);

  @include tui.wrapper-hover {
    background: var(--tui-clear-hover);
  }

  @include tui.wrapper-readonly {
    background: transparent;
  }

  @include tui.wrapper-invalid {
    &:after {
      color: var(--tui-error-fill);
    }
  }

  @include tui.wrapper-focus {
    background: transparent;

    --tui-focus: var(--tui-text-01);
  }
}
