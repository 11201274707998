.side-panel-wrapper {
  min-height: 100%;
  // we want to scroll the content of our panel
  overflow-x: auto;
  // but then we need to reserve some space
  // for box-shadow (cut off due to overflow hidden)
  padding-left: 32px;

  tui-root._mobile & {
    padding-left: 0;
  }
}

.side-panel {
  height: 100%;
  // enable pointer events again, because host disabled it
  pointer-events: auto;
  width: var(--side-panel-width, 36rem);
  background: var(--color-bg-light);
  box-shadow: var(--shadow-md);
  display: flex;
  flex-direction: column;

  &.side-panel--lg {
    --side-panel-width: 44rem;
  }

  &.side-panel--md {
    --side-panel-width: 36rem;
  }

  &.side-panel--sm {
    --side-panel-width: 28rem;
  }

  tui-root._mobile & {
    --side-panel-width: 100%;
  }
}

.side-panel__header {
  flex-grow: 0;
  padding: var(--component-padding);
}

.side-panel__content {
  flex-grow: 1;
  overflow-x: auto;
  padding: var(--component-padding);
}

.side-panel__header + .side-panel__content {
  padding-top: 0;
}

.side-panel__footer {
  flex-grow: 0;
  padding: var(--component-padding);
  box-shadow: 0px -4px 14px rgba(0, 0, 0, 0.08);
  border-top: 1px solid var(--color-contrast-lower);
  z-index: 10;
}
