@use "@taiga-ui/core/styles/taiga-ui-local" as tui;

tui-wrapper[data-appearance="icon"],
[tuiWrapper][data-appearance="icon"] {
  background: transparent;
  color: var(--tui-base-06);

  @include tui.wrapper-hover {
    color: var(--tui-base-07);
    opacity: 1;
  }

  @include tui.wrapper-active {
    color: var(--tui-base-08);
    opacity: 1;
  }

  &[data-mode="onDark"] {
    color: var(--tui-text-01-night);
    opacity: var(--tui-disabled-opacity);

    @include tui.wrapper-focus {
      --tui-focus: var(--tui-base-01);
    }
  }

  &[data-mode="onLight"] {
    color: var(--tui-text-01);
    opacity: var(--tui-disabled-opacity);
  }
}
