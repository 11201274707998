// Structure
// ***********

//<div class="form-row">
//  <label tuiLabel="My label">
//    <tui-input formControlName="name">My label</tui-input>
//    <tui-error formControlName="name" [error]="[] | tuiFieldError | async"></tui-error>
//  </label>
//</div>

// Variables
// ***********

:root,
[data-theme="dark"],
[data-mode="onDark"],
[tuimode="onDark"] {
  --form-row-gap: var(--space-xxxs);
  --form-text: var(--text-sm);
  --form-error-text: var(--text-xs);
  --form-label-color: var(--tui-text-02);
}

// Basics
// ***********

.form {
  .form-row.info,
  .form-row .info {
    // Can be used for additional information within a form
    font-size: var(--form-text);
    color: var(--tui-text-01);
  }

  tui-error {
    font-size: var(--form-error-text) !important; // overwrite Taiga UI
  }

  [tuiLabel],
  .form-row > label {
    min-height: auto !important; // overwrite Taiga UI
    color: var(--form-label-color);

    > .t-label {
      font-size: var(--form-text) !important; // overwrite Taiga UI
    }

    // show "*" for all required fields
    &.required .t-label {
      ::after {
        content: "*";
        margin-left: var(--space-xxxxs);
        color: var(--color-accent);
        font-weight: 500;
      }
    }
  }

  // we tweak the design a little bit for forms in filter panel
  &.form--filters {
    --form-row-gap: var(--space-xs);
    --form-label-color: var(--color-contrast-high);

    [tuiLabel] > .t-label,
    .form-row label > .t-label {
      font-weight: 700 !important;
      text-transform: uppercase;
      margin-bottom: var(--space-xxxxs);
    }
    .required > .t-label {
      ::after {
        content: "*";
        margin-left: var(--space-xxxxs);
        color: var(--color-accent);
        font-weight: 500;
      }
    }
  }
}

.form .form-lot {
  --form-row-gap: var(--space-xxxs);
  [tuiLabel] > .t-label,
  .form-row > label > .t-label {
    font-weight: normal !important;
    text-transform: none;
    margin-bottom: 0;
  }
  margin-bottom: var(--space-xs);
}

.form-row {
  margin-bottom: var(--form-row-gap);
}

label tui-radio-list {
  margin-top: var(--space-xxs);
}

.form-header {
  font-size: var(--text-md);
  font-weight: bold;
  border-bottom: 1px solid var(--color-contrast-lower);
  margin-bottom: var(--space-md);
  padding-bottom: var(--space-xxxs);
}

.form-subheader {
  font-size: var(--text-sm);
  font-weight: bold;
  text-transform: uppercase;
  border-top: 1px solid var(--color-contrast-lower);
  padding-top: var(--space-xxxs);
  margin: var(--space-md) 0;
  &:first-child {
    margin-top: 0;
  }

  // we use different style when inside filters
  .form--filters & {
    border-top: none;
    padding-top: 0;
    margin: 0;
  }
}

.form-header + .form-subheader {
  border-top: none;
  padding-top: 0;
  margin-top: calc(var(--space-xxxs) * -1);
}

// Layouts
// ***********

// Horizontal layout (label left, value/input right)
.form--layout-grid {
  [tuiLabel],
  .form-row > label {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    > .t-label {
      width: 38%;
      padding: 0.38rem var(--space-xxs) 0 0;
    }

    > .t-content {
      width: 62%;
      &:has(> .radio-group) {
        width: fit-content;
        margin-right: auto;
      }
    }
  }

  .radio-group-wrapper {
    display: flex;
    font-size: var(--text-sm, 0.75rem);
    line-height: 20px;
    text-overflow: ellipsis;
    color: var(--form-label-color);
    cursor: default;

    .radio-group-label {
      width: 38%;
      padding: 0.38rem var(--space-xxs) 0 0;
      text-align: end;
    }
  }
}

// Vertical layout (label top, value/input beneath)
.form--layout-stacked {
  [tuiLabel],
  .form-row > label {
    > .t-label {
      // Todo: display: none; when in read mode?
      padding: 0 0 var(--space-xxxxs) 0;
    }
  }
  .required > .t-label {
    ::after {
      content: "*";
      margin-left: var(--space-xxxxs);
      color: var(--color-accent);
      font-weight: 500;
    }
  }
}

// Variations
// ***********

// Label is aligned at the right side
.form--label-align-end {
  [tuiLabel] > .t-label,
  .form-row > label > .t-label {
    justify-content: flex-end;
    text-align: end;
  }
}

// Here we overwrite the disabled state for read-only mode forms
// This has to be done, because there is only a single state both variations
.form--read-only tui-wrapper[data-appearance="textfield"][data-state="disabled"] {
  opacity: 1;
  background: var(--color-white);
  &:after {
    color: var(--tui-base-02);
  }
}

// Related
// ***********

.radio-group {
  display: flex;
  align-items: center;
  padding: var(--space-xxxs) 0;
  > * {
    margin-right: var(--space-md);
  }
}

// Todo: Remove when Bupa is adapted
tui-stepper.ion-stepper button {
  flex-direction: column;
  text-transform: uppercase;
  .marker {
    margin-right: 0 !important;
    margin-bottom: var(--space-xxs);
  }
  &:last-of-type {
    margin-right: 0 !important;
  }
}

.col-separator {
  flex: 0 0 1px;
  background: var(--tui-base-03);
}

.t-placeholder > .required,
.required > .radio-group-label {
  &::after {
    content: "*";
    margin-left: var(--space-xxxxs);
    color: var(--color-accent);
    font-weight: 500;
  }
}
