@use "@taiga-ui/core/styles/taiga-ui-local" as tui;

tui-wrapper[data-appearance="flat-in-menu"],
[tuiWrapper][data-appearance="flat-in-menu"] {
  background: transparent;
  color: var(--tui-text-03);

  @include tui.wrapper-hover {
    color: var(--tui-primary);
  }

  &[data-mode="onDark"],
  &[data-mode="onLight"] {
    background: transparent;
  }
}
