@use "sass:math";

.summary-table {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--form-text);

  // Calculate max-width to prevent summary-table getting so wide it allows
  // the <dt> of the next line appearing at the end of the current line.
  max-width: calc(11rem / (0.38 / 2) - 1px);

  dt {
    margin-bottom: var(--space-xxxs);
    padding-right: var(--space-md);
    flex: 1 0 38%;
    max-width: 11rem;
    color: var(--form-label-color);
    text-align: left;
    overflow: hidden;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  dd {
    flex: 1 0 62%;
    margin-bottom: var(--space-xxxs);
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

// An alternative to the above, using CSS Grid.
.summary-table-grid {
  display: grid;
  grid-template-columns: minmax(min-content, max-content) 1fr;
  grid-template-rows: auto;

  column-gap: var(--space-md);
  row-gap: var(--space-xxxs);

  align-items: center;

  font-size: var(--form-text);

  dt {
    color: var(--form-label-color);
  }
}
