:root {
  --list-space-y: var(--space-xxs); // vertical gaps
  --list-offset: var(--space-xs); // sublist horizontal offset
  --list-line-height: var(--body-line-height); // items line-height
}

.list,
.text-component .list {
  padding-left: 0;
  list-style: none;

  ul,
  ol {
    list-style: none;
    margin: 0; // reset
    margin-top: calc(var(--list-space-y) / 2);
    padding-top: calc(var(--list-space-y) / 2);
    padding-left: var(--list-offset);
  }

  li {
    padding-bottom: calc(var(--list-space-y) / 2);
    margin-bottom: calc(var(--list-space-y) / 2);
    line-height: var(--list-line-height);
  }

  > li:last-child,
  ul > li:last-child,
  ol > li:last-child {
    margin-bottom: 0;
  }

  &:not(.list--border) > li:last-child,
  ul > li:last-child,
  ol > li:last-child {
    padding-bottom: 0;
  }
}

/* #region (ul + ol) */
.list--ul,
.text-component .list--ul,
.list--ol,
.text-component .list--ol {
  --list-offset: calc(var(--list-bullet-size) + var(--list-bullet-margin-right));

  ul,
  ol {
    padding-left: 0;
  }

  li {
    @supports (--css: variables) {
      padding-left: var(--list-offset) !important;
    }
  }

  li::before {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
    top: -0.1em;

    @supports (--css: variables) {
      width: var(--list-bullet-size) !important;
      height: var(--list-bullet-size) !important;
      margin-left: calc(var(--list-bullet-size) * -1) !important;
      left: calc(var(--list-bullet-margin-right) * -1) !important;
    }
  }
}

// unordered list
.list--ul,
.text-component .list--ul {
  --list-bullet-size: 7px; // dot width and height
  --list-bullet-margin-right: 12px; // gap between bullet and content

  > li::before {
    // bullet
    content: "";
    border-radius: 50%;
    color: var(--color-primary); // bullet color
    background-color: currentColor;
  }

  ul li::before {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px currentColor;
  }
}
