@use "@taiga-ui/core/styles/taiga-ui-local" as tui;

tui-wrapper[data-appearance="primary"],
[tuiWrapper][data-appearance="primary"] {
  background: var(--tui-primary);
  color: var(--tui-primary-text);

  @include tui.wrapper-hover {
    background: var(--tui-primary-hover);
  }

  @include tui.wrapper-active {
    background: var(--tui-primary-active);
  }

  @include tui.wrapper-invalid {
    color: var(--tui-negative-night);
    background: var(--tui-error-bg);

    @include tui.wrapper-hover {
      background: var(--tui-error-bg-hover);
    }

    @include tui.wrapper-active {
      background: var(--tui-error-bg-hover);
    }

    @include tui.wrapper-focus {
      --tui-focus: var(--tui-error-fill);
    }
  }
}
