// There is a mismatch between Taiga UI icons and our processed Material icons:
// Material icons are 24px in size, and we set them to 1em while processing
// The Taiga UI icons have two shapes: Large: 24px / Normal: 16px. They are set to 1.5em or 1em, respectively
// The marker icon only works with the large version (looks like that)
// But with our approach we need to use the small ones to fit (when using Taiga icons, which should never be the case)

// Now, we need to adjust the scale factor because it doesn't fit with the Material icons (which we should always use)
// In comment you see Taiga UI scale and the original width/height (which is same for us)
$size-scale-mapping: (
  "xxs": 0.75,
  // 0.58; 1.5rem
  "xs": 1,
  // 0.83; 2rem
  "s": 1.2,
  // 1; 2.5rem
  "m": 1.3,
  // 1; 3rem
  "l": 1.68,
  // 1.33; 4rem
  "xl": 2.75,
  // 2.33; 6rem
  "xxl": 3.6, // 3.33; 8rem
);

@each $size, $scale-factor in $size-scale-mapping {
  #ion tui-marker-icon[new][data-size="#{$size}"].marker-rescaled .t-icon {
    transform: scale(#{$scale-factor});
  }
}
