// Based on https://github.com/Tinkoff/taiga-ui/blob/main/projects/kit/components/tag/tag.style.less
// Just added to overwrite / adapt the background and text color definitions for Tag (https://taiga-ui.dev/components/tag)

tui-tag,
a[tuiTag] {
  .t-text {
    font-weight: bold !important;
  }

  &.font-normal .t-text {
    font-weight: 400 !important;
  }

  // Taiga UI only "accepts" small versions of icons here now
  // So, we need to scale the font size here to fit 16px
  // Todo: See https://jira.ionity.eu/browse/ITDEV-34297
  > .t-tag > .t-content > tui-svg {
    font-size: 1rem !important;
  }

  // Design state: Neutral / Unknown / Disabled / Off
  &[data-tui-host-status="default"],
  &.status-default {
    --tui-base-06: var(--color-bg-dark) !important;
    --tui-text-01-night: var(--color-contrast-higher) !important;
  }

  // Design state: Neutral / Unknown / Disabled / Off
  // Not part of Taiga UI styles, but dedicated status
  &.status-neutral {
    background-color: var(--tui-neutral-bg);
    color: var(--tui-neutral-fill);

    &._hoverable:hover,
    &._hoverable:active {
      background-color: var(--tui-neutral-bg-hover);
    }
  }

  // Design state: Info / Stand by
  // Not part of Taiga UI styles, but dedicated status
  &.status-info {
    background-color: var(--tui-info-bg);
    color: var(--tui-info-fill);

    &._hoverable:hover,
    &._hoverable:active {
      background-color: var(--tui-info-bg-hover);
    }
  }

  // Design state: Negative / Error
  &[data-tui-host-status="error"],
  &.status-error {
    //--tui-error-bg: var(--tui-error-bg) !important;
    --tui-text-01: var(--tui-error-fill) !important;
  }

  // Design state: Positive / Actual / Active
  &[data-tui-host-status="success"],
  &.status-success {
    //--tui-success-bg: var(--tui-success-bg) !important;
    --tui-text-01: var(--tui-success-fill) !important;
  }

  // Design state: Warning
  &[data-tui-host-status="warning"],
  &.status-warning {
    //--tui-success-bg: var(--tui-warning-bg) !important;
    --tui-text-01: var(--tui-warning-fill) !important;
  }
}
