@use "@taiga-ui/core/styles/taiga-ui-local" as tui;

tui-wrapper[data-appearance="secondary"],
tui-wrapper[data-appearance="flat"],
[tuiWrapper][data-appearance="secondary"],
[tuiWrapper][data-appearance="flat"] {
  background: var(--tui-secondary);
  color: var(--tui-link);

  @include tui.wrapper-hover {
    background: var(--tui-secondary-hover);
  }

  @include tui.wrapper-active {
    background: var(--tui-secondary-active);
  }

  @include tui.wrapper-invalid {
    color: var(--tui-error-fill);
    background: var(--tui-error-bg);

    @include tui.wrapper-hover {
      background: var(--tui-error-bg-hover);
    }

    @include tui.wrapper-focus {
      --tui-focus: var(--tui-error-fill);
    }
  }

  &[data-mode="onDark"] {
    background: var(--tui-clear-inverse);
    color: var(--tui-text-01-night);

    @include tui.wrapper-hover {
      background: var(--tui-clear-inverse-hover);
    }

    @include tui.wrapper-active {
      background: var(--tui-clear-inverse-active);
    }

    @include tui.wrapper-focus {
      --tui-focus: var(--tui-text-01-night);
    }
  }

  &[data-mode="onLight"] {
    background: var(--tui-clear);
    color: var(--tui-text-01);

    @include tui.wrapper-hover {
      background: var(--tui-clear-hover);
    }

    @include tui.wrapper-active {
      background: var(--tui-clear-active);
    }
  }
}

tui-wrapper[data-appearance="flat"],
[tuiWrapper][data-appearance="flat"] {
  background: transparent;
  color: var(--tui-text-01);

  @include tui.wrapper-hover {
    background: var(--tui-base-02);
    color: var(--tui-primary);
  }

  &[data-mode="onDark"],
  &[data-mode="onLight"] {
    background: transparent;
  }
}
