:root,
* {
  --size-xxxxs: 0.25rem;
  --size-xxxs: 0.5rem;
  --size-xxs: 1rem;
  --size-xs: 2rem;
  --size-sm: 3rem;
  --size-md: 5rem;
  --size-lg: 8rem;
  --size-xl: 13rem;
  --size-xxl: 16rem;
  --size-xxxl: 21rem;
  --size-xxxxl: 34rem;
  --component-padding: var(--space-md);
}

.max-height-xxl {
  max-height: var(--size-xxl, 16rem);
}
