.separator {
  display: block;
  width: 1px; // var(--space-xxxxs);
  height: var(--space-lg);
  background: #a1a1cc; // var(--color-contrast-low);
  margin: 0 var(--space-sm);
  &.vertical {
    width: var(--space-sm);
    height: var(--space-xxxxs);
    margin: var(--space-xxxs) auto;
  }
}

.card-like {
  background: var(--color-bg-light);
  border: 1px solid var(--tui-base-04);
  border-radius: var(--radius-md);
  // box-shadow: var(--shadow-xs);
}

.spacer {
  flex: 1 1 auto;
}

.tag {
  background: var(--tui-base-06);
  padding: var(--space-xxxxs);
  font-size: var(--text-xs);
  color: var(--color-white);
  border-radius: var(--radius-md);
}

// Styles for a circular dot element
.dot {
  height: var(--size-xxs);
  width: var(--size-xxs);
  background-color: var(--color-primary);
  border-radius: 50%;
  display: inline-block;
  margin-right: var(--space-xxs);
}

.dot.dot--sm {
  height: var(--size-xxxs);
  width: var(--size-xxxs);
}
