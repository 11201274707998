@use "../../styles/global-variables" as vars;

%grid-auto {
  display: grid;
  // gap: var(--gap-y, 0px) var(--gap-x, 0px);
  grid-template-columns: repeat(auto-fill, minmax(var(--col-min-width), 1fr)); // auto add new cols
}

// and now make some sizes, based on our space design system
@each $size in vars.$spaces {
  .grid-auto-#{$size} {
    @extend %grid-auto;
    // @extend .gap-#{$size};
    --col-min-width: var(--size-#{$size});
  }
}

// add basic class to easily customize own grid sizes
.grid-auto {
  @extend %grid-auto;
}
